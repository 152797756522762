import styled from 'styled-components'
import { Input } from 'antd'

export const StyledInput = styled(Input)`
  width: 517px;
  color: #f16749;
  font-size: 16px;
  font-family: Lato;
  font-weight: 400;
  line-height: 24px;
  height: 48px;
  max-width: 100%;
  @media (max-width: 767px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 400px;
  }

  & input {
    font-size: 16px;
  }
`

export const StyledAutocompleteDropDown = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  padding: 9px 12px;
  background: #fff;
  z-index: 9;
  margin-top: 4px;
  box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 30%);

  &:empty {
    padding: 0;
  }

  & > div {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
`

import React from 'react'
import { Button } from 'antd'
import { Layout } from 'antd'
import styled from 'styled-components'

export const StyledMenuBtn = styled(Button)`
  height: auto;
  padding: 0;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
`

const { Content } = Layout

const PageLayout: React.FC = ({ children }) => {
  return (
    <Layout className="layout">
      <Content>{children}</Content>
    </Layout>
  )
}

export default PageLayout
